<template>
  <li :class="[{ active: isActive }, { open: isOpen }, 'list']">
    <div class="accord-head" @click="clicked()">
      <div class="accord-icon">
        <i :class="icon"></i>
      </div>
      <h3 class="title">{{ title }}</h3>
      <i class="fas fa-angle-down" v-if="hasChilds()"></i>
    </div>
    <ul class="sub-menu" v-if="hasChilds()">
      <li class="menu-item" v-for="(child, index) in showChilds" :key="index">
        <router-link
          :to="{ name: child.name }"
          v-if="child.name !== 'gtNexus' || $store.getters.getCmpyUUID === 'COM8CBB904D20220423123255'"
          >{{ child.title }}</router-link
        >
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: ["title", "icon", "name", "isOpen", "childs", "miniDrawer"],
  methods: {
    hasChilds() {
      return this.childs !== undefined && this.childs !== null && this.childs.length > 0;
    },
    clicked() {
      if (this.miniDrawer) {
        if (this.hasChilds()) this.$router.push({ name: this.childs[0]?.name });
        else this.$router.push({ name: this.name });
      } else if (this.hasChilds()) this.$emit("update:isOpen", !this.isOpen);
      else this.$router.push({ name: this.name });
    },
  },
  computed: {
    isActive() {
      switch (this.name) {
        case "material":
          return this.$store.getters.currentRouteIsMaterial;
        case "BOM":
          return this.$store.getters.currentRouteIsBOM;
        case "PO":
          return this.$store.getters.currentRouteIsPurchaseOrder;
        case "financialPlan":
          return this.$store.getters.currentRouteIsFinancialPlan;
        case "system":
          return this.$store.getters.currentRouteIsSystem;
        default:
          return false;
      }
    },
    showChilds() {
      if (!this.hasChilds()) return [];

      const cmpy = this.$store.getters["user/getCompanyList"]?.find(
        c => c.cmpyUUID === this.$store.getters.getCmpyUUID,
      );
      const isManager = cmpy?.userRole === "M";

      return this.childs.filter(child => (child.isManager === true ? isManager : true));
    },
  },
};
</script>

<style></style>
