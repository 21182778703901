import axios from "@/plugins/axios";

const code = {
  namespaced: true,
  state: {
    common: [],
    commonOrg: [],
    codeMaster: [],
  },
  getters: {
    getMtrlCateAccessoryCode() {
      //return this.materialCategory?.find(mc=> mc.itemDesc === '악세사리').itemCd;
      return "Accessory";
    },
    getMtrlCateFabricCode() {
      return "Fabric";
    },
    getCurrencyCode: state => name => {
      return state.common.currency.find(c => c.itemName === name)?.itemCd;
    },
    getMaterialTagTypes: state => {
      return Object.keys(state.codeMaster["MATERIAL-TAG"].child);
    },
  },
  mutations: {
    setCommonCode(state, { code, _categories, codeMaster }) {
      state.common = code;
      state.codeMaster = codeMaster;
      // state.commonOrg = { codes: _codes, categories: _categories };
      state.commonOrg = { categories: _categories };
    },
  },
  actions: {
    getCommonCode({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/commonCode").then(
          res => {
            if (res.data.status === 200) {
              const _codes = res.data.data.codes;
              const _categories = res.data.data.categories;
              const _excludeCategories = ["ADM_UPPER_CLASS", "country"];
              const codes = {};

              //ADM_UPPER_CLASS
              const admUpperClass = _codes.filter(code => code.cateCd === "ADM_UPPER_CLASS");
              const codeMaster = {};
              admUpperClass.forEach(c => {
                _excludeCategories.push(c.itemCd);
                codeMaster[c.itemCd] = c;

                const child = _codes.filter(code => code.cateCd === c.itemCd);
                codeMaster[c.itemCd].child = {};
                child.forEach(cd => {
                  codeMaster[c.itemCd].child[cd.itemCd] = cd;
                });
              });

              //country, city
              const countries = _codes.filter(code => code.cateCd === "country");
              countries.forEach(c => {
                _excludeCategories.push(c.itemCd);
                c.city = _codes.filter(code => code.cateCd === c.itemCd);
              });
              codes.country = countries;

              _categories.forEach(cate => {
                if (!_excludeCategories.includes(cate)) codes[cate] = _codes.filter(code => code.cateCd === cate);
              });

              commit("setCommonCode", { code: codes, _codes, _categories, codeMaster });

              resolve();
            } else {
              reject();
            }
          },
          error => {
            reject(error);
          },
        );
      });
    },
  },
};

export default code;
