const loginRouter = [
  {
    path: "",
    name: "login",
    component: () => import("@/components/login/LoginIndex"),
  },
  {
    path: "email",
    name: "emailLogin",
    component: () => import("@/components/login/EmailLogin"),
  },
  {
    path: "signUp",
    name: "signUp",
    component: () => import("@/components/login/SignUp"),
  },
  {
    path: "forgot-password",
    name: "forgotPassword",
    component: () => import("@/components/login/ForgotPassword"),
  },
];

export default loginRouter;
