import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import moment from "./plugins/moment";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$mathFix = 4;
Vue.prototype.$m = moment.moment;
Vue.prototype.$s3 = "https://vgo-dev.s3.ap-northeast-2.amazonaws.com/";
Vue.prototype.$pagePV = 9;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount("#app");

// ? vue toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true,
  position: "top-right",
  timeout: 5 * 1000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
});
