const companyRouter = [
  //#region Index
  {
    path: "",
    name: "company",
    component: () => import("@/components/company/Dashboard"),
  },
  //#endregion
  //#region Material
  {
    path: "material",
    name: "material",
    component: () => import("@/components/company/material/Material"),
  },
  //#endregion
  //#region BOM
  {
    path: "BOM",
    name: "BOM",
    component: () => import("@/components/company/bom/Bom"),
    props: route => ({
      bomUUID: route.query.bomUUID,
      loadSearchOption: String(route.query.loadSearchOption).toLowerCase() === "true",
    }),
  },
  {
    path: "CBD/:cbdUUID",
    name: "CBD",
    component: () => import("@/components/company/bom/cbd/Cbd"),
    props: route => ({ fromSearch: String(route.query.fromSearch).toLowerCase() === "true" }),
  },
  {
    path: "MCL/:mclUUID",
    name: "MCL",
    component: () => import("@/components/company/bom/mcl/Mcl"),
    props: route => ({ fromSearch: String(route.query.fromSearch).toLowerCase() === "true" }),
  },
  //#endregion
  //#region PO
  {
    path: "issued-rmpo",
    name: "issuedRMPO",
    component: () => import("@/components/company/po/IssuedRmpo.vue"),
  },
  {
    path: "issued-rmpo/:poUUID",
    name: "issuedRMPODetail",
    component: () => import("@/components/company/po/RmpoDetail.vue"),
  },
  {
    path: "received-rmpo",
    name: "receivedRMPO",
    component: () => import("@/components/company/po/ReceivedRmpo.vue"),
  },
  {
    path: "received-rmpo/:poUUID",
    name: "receivedRMPODetail",
    component: () => import("@/components/company/po/RmpoDetail.vue"),
  },
  {
    path: "garmentPO",
    name: "garmentPO",
    component: () => import("@/components/company/garmentPO/GarmentPOList"),
  },
  {
    path: "garmentPO/:garmentPOUUID",
    name: "garmentPODetail",
    component: () => import("@/components/company/garmentPO/GarmentPODetail"),
  },
  {
    path: "gtNexus",
    name: "gtNexus",
    component: () => import("@/components/company/gtNexus/GtNexus"),
  },
  //#endregion
  //#region Financial Plan
  {
    path: "financial-plan",
    name: "financialPlan",
    component: () => import("@/components/company/financialPlan/FinancialPlan"),
  },
  //#endregion
  //#region System
  {
    path: "data-management",
    name: "dataManagement",
    component: () => import("@/components/company/system/DataManagement"),
  },
  {
    path: "user-management",
    name: "userManagement",
    component: () => import("@/components/company/system/UserManagement"),
  },
  {
    path: "company-information",
    name: "companyInformation",
    component: () => import("@/components/company/system/CompanyInformation"),
  },
  //#endregion
];

export default companyRouter;
