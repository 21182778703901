<template>
  <v-system-bar app window height="80" class="header" dark>
    <img class="pl-7 pointer" :src="require('images/logo.png')" alt="vgo" @click="$router.push('/')" />

    <v-select
      dense
      solo
      hide-details
      flat
      dark
      class="ml-8"
      ref="refPageSelect"
      v-model="selectedPage"
      :items="getPageList"
      background-color="#3B8176">
      <template v-slot:prepend-inner>
        <v-icon color="rgba(255, 255, 255, 0.5)"> mdi-domain </v-icon>
      </template>
      <template v-slot:append>
        <v-icon> mdi-chevron-down </v-icon>
      </template>
      <template v-slot:item="data">
        <v-list-item-content
          :style="!isActiveMenu(data.item) && 'cursor:default !important'"
          @click="pageClick($event, data.item)"
          class="h-select-company-item">
          <v-list-item-title :class="{ 'font-gray3': !isActiveMenu(data.item) }">
            {{ data.item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>

    <v-spacer />

    <!-- <v-btn text @click="changeLocale" min-width="12px" class="pa-0">
      {{ getLocal }}
    </v-btn> -->

    <!-- <div class="divider"></div> -->

    <!-- <v-badge overlap content="3" color="error">
      <v-icon class="pointer" size="25" color="white" @click="$toast.info('Comming Soon')">
        mdi-bell-outline
      </v-icon>
    </v-badge>

    <div class="divider"></div> -->

    <v-icon size="30" color="white"> mdi-account-outline </v-icon>
    <span> {{ $store.state.user.name }} ({{ $store.state.user.email }})</span>

    <tooltip-icon
      iconClass="ml-5 pointer"
      size="25"
      color="white"
      icon="mdi-login"
      tooltip="Logout"
      @click="$store.commit('user/logout')">
    </tooltip-icon>

    <my-company-list-edit :listEditShow.sync="listEditShow" />
  </v-system-bar>
</template>

<script>
import TooltipIcon from "@/components/common/icons/TooltipIcon.vue";
import MyCompanyListEdit from "@/components/community/MyCompanyListEdit.vue";
import { company } from "@/utils/statusHandler";
import moment from "@/plugins/moment";
import { hasValue } from "@/utils/util";

export default {
  components: { TooltipIcon, MyCompanyListEdit },
  data() {
    return {
      localeKey: "vgo-locale",
      communityPage: { text: "Community Page", value: "community" },
      adminPage: { text: "Admin Page", value: "admin" },
      editPage: { text: "Edit Company List", value: "edit" },
      companyInterval: null,
      listEditShow: false,
      refPageSelect: null,
    };
  },
  computed: {
    getLocal() {
      return this.$i18n.locale.toUpperCase();
    },
    getPageList() {
      const companyList = this.$store.getters["user/getCompanyList"];

      const defaultPages = [this.communityPage];
      if (this.isAdmin) defaultPages.push(this.adminPage);

      if (companyList?.length > 0) {
        return [
          ...defaultPages,
          { divider: true },
          ...this.$store.getters["user/getCompanyList"],
          { divider: true },
          this.editPage,
        ];
      } else {
        return defaultPages;
      }
    },
    selectedPage: {
      get() {
        let val = this.$store.state.currentRoute.name;
        if (this.$store.getters.currentRouteIsCompany) val = this.$store.getters.getCmpyUUID;
        return val;
      },
      set() {},
    },
    isAdmin() {
      return this.$store.state.user.userRole <= 0;
    },
  },
  methods: {
    changeLocale() {
      const locale = this.$i18n.locale === "en" ? "ko" : "en";
      this.$i18n.locale = locale;
      moment.changeLocale();
      localStorage.setItem(this.localeKey, locale);
    },
    getCompanyList() {
      this.$store.dispatch("user/getCompanyList");
    },
    isActiveMenu(item) {
      return (
        item.value === this.communityPage.value ||
        item.value === this.adminPage.value ||
        item.value === this.editPage.value ||
        this.getStatusInfo(item.isUse).active === true
      );
    },
    getStatusInfo: company.requestStatusHandler,
    pageClick(event, item) {
      if (hasValue(item.isUse) && company.isJoin(item.isUse)) {
        //해당 회사 페이지로 이동
        const path = `/company/${item.value}`;
        if (this.$store.state.currentRoute.path !== path) {
          this.$router.push(path);
        }
      } else {
        if (item.value === this.communityPage.value) {
          //커뮤니티 페이지로 이동
          this.$store.state.currentRoute.name !== "community" && this.$router.push({ name: "community" });
        } else if (item.value === this.adminPage.value) {
          this.$store.state.currentRoute.name !== "admin" && this.$router.push({ name: "admin" });
        } else if (item.value === this.editPage.value) {
          //회사 수정 팝업창 표시
          event.stopPropagation();
          this.$refs.refPageSelect?.blur(); //selectBox 닫기
          this.listEditShow = true;
        } else {
          event.stopPropagation();
        }
      }
    },
  },
  created() {
    // 저장된 locale setting
    // if (localStorage.getItem(this.localeKey)) {
    //   this.$i18n.locale = localStorage.getItem(this.localeKey);
    //   moment.changeLocale();
    // }

    // 소속된 회사 30초마다 불러오기
    this.companyInterval = setInterval(this.getCompanyList, 30 * 1000);
  },
  destroyed() {
    clearInterval(this.companyInterval);
  },
};
</script>

<style></style>
