import axios from "@/plugins/axios";
import { hasValue, _deepCopy } from "@/utils/util";

const company = {
  namespaced: true,
  state: {
    company: {},
  },
  mutations: {
    addCompanyCode(state, { cmpyUUID, code }) {
      state.company[cmpyUUID] = code;
      state.company = { ...state.company };
    },
    changeCompanyCode(state, { cmpyUUID, codeCategory, code }) {
      if (state.company[cmpyUUID][codeCategory]) {
        state.company[cmpyUUID][codeCategory] = _deepCopy(code);
      }
    },
    changeCompanyCodeDetail(state, { cmpyUUID, codeCategory, parentKey, itemDesc }) {
      const codeCate = state.company[cmpyUUID][codeCategory];
      if (codeCate) {
        const codeDetail = codeCate.find(c => c.itemCd === parentKey);
        if (codeDetail) codeDetail.itemDesc = _deepCopy(itemDesc);
      }
    },
  },
  getters: {
    getMtrlType: state => (cmpyUUID, cate) => {
      if (cmpyUUID && cate) return state.company[cmpyUUID][`${cate.toLowerCase()}Type`];
      else return null;
    },
    getAllBrands: state => cmpyUUID => {
      if (!cmpyUUID) return null;
      const buyers = state.company[cmpyUUID].buyer;
      const allBrands = [];
      buyers.forEach(buyer => {
        if (hasValue(buyer) && hasValue(buyer.itemDesc)) {
          const brands = buyer.itemDesc.split("|$|");
          allBrands.push(...brands);
        }
      });
      return allBrands;
    },
  },
  actions: {
    getCompanyCode({ commit, state }, cmpyUUID) {
      return new Promise((resolve, reject) => {
        if (!cmpyUUID) reject();
        else if (Object.keys(state.company).includes(cmpyUUID)) resolve();
        else {
          axios.get("/commonCodeByCmpyUUID", { data: { cmpyUUID } }).then(
            res => {
              if (res.data.status === 200) {
                const _codes = res.data.data.codes;
                const _categories = res.data.data.categories;

                const codes = {};
                _categories.forEach(cate => {
                  codes[cate] = _codes.filter(code => code.cateCd === cate);
                });

                commit("addCompanyCode", { cmpyUUID, code: { ...codes, _categories, _codes } });

                resolve();
              } else {
                reject();
              }
            },
            error => {
              reject(error);
            },
          );
        }
      });
    },
  },
};

export default company;
