import axios from "@/plugins/axios";
import { hasValue } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
import router from "@/router/index";

const user = {
  namespaced: true,
  state: {
    token: undefined,
    name: undefined,
    email: undefined,
    mobilePhone: undefined,
    userRole: undefined,
    companyList: [],
    bomSearchOption: {},
  },
  mutations: {
    setUserInfo(state, { token, email, name, mobilePhone, userRole }) {
      state.token = token;
      state.name = name;
      state.email = email;
      state.mobilePhone = mobilePhone;
      state.userRole = userRole;
    },
    logout(state) {
      Object.keys(state).forEach(key => (state[key] = undefined));
      router.push({ name: "login" });
    },
    setCompanyList(state, { companyList }) {
      if (!companyList) companyList = [];
      state.companyList = companyList;
    },
    setBomSearchOption(state, bomSearchOption) {
      state.bomSearchOption = bomSearchOption;
    },
  },
  getters: {
    getCompanyList(state) {
      return state.companyList
        ?.filter(({ isUse }) => isUse === "Y")
        .map(({ companyName, cmpyUUID, isUse, userRole }) => ({
          text: companyName,
          value: cmpyUUID,
          cmpyUUID,
          isUse,
          userRole,
        }));
    },
    includesCompany: state => cmpyUUID => {
      return typeof state.companyList.find(c => c.cmpyUUID === cmpyUUID) === "object";
    },
  },
  actions: {
    login({ commit }, { email, passwd }) {
      return new Promise((resolve, reject) => {
        axios.post("/signIn", { email, passwd }).then(
          res => {
            if (res.data.status === 200 && hasValue(res.data.data)) {
              commit("setUserInfo", { ...res.data.data });
              resolve();
            } else {
              codeHandler(res.data);
              reject();
            }
          },
          error => {
            reject(error);
          },
        );
      });
    },
    superLogin({ commit }, { email }) {
      return new Promise((resolve, reject) => {
        axios.post("admin/super/login", { email }).then(
          res => {
            if (res.data.status === 200 && hasValue(res.data.data)) {
              commit("setUserInfo", { ...res.data.data });
              resolve();
            } else {
              codeHandler(res.data);
              reject();
            }
          },
          error => {
            reject(error);
          },
        );
      });
    },
    getCompanyList({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get("/user/company/list").then(
          res => {
            if (res.data.status === 200) {
              commit("setCompanyList", { companyList: res.data.data });
              resolve();
            } else {
              //codeHandler(res.data);
              reject();
            }
          },
          error => {
            reject(error);
          },
        );
      });
    },
  },
};

export default user;
