import Vue from "vue";
import i18n from "@/plugins/i18n";
import { hasValue } from "@/utils/util";

const codeHandler = function (data) {
  if (!hasValue(data?.code)) {
    console.error("codeHandler no code Error");
    return console.error(data);
  }

  switch (data.code) {
    case "C010":
    case "C006":
    case "I001":
    case "USER001":
    case "USER003":
    case "USER004":
    case "USER010":
    case "USER032":
    case "USER033":
    case "USER034":
    case "M001":
    case "M006":
    case "M007":
    case "M008":
    case "M009":
    case "M010":
    case "M011":
    case "BOM001":
    case "BOM002":
    case "BOM003":
    case "BOM004":
    case "BOM005":
    case "BOM006":
    case "CBD001":
    case "CBD002":
    case "MCL001":
    case "MCL002":
    case "MCL003":
    case "MCL004":
    case "MCL005":
    case "MCL006":
    case "GARMENTPO001":
    case "GARMENTPO002":
      Vue.$toast.warning(i18n.t(`axios.code.${data.code}`));
      break;
    default:
      Vue.$toast.warning(data?.message || "not defined error");
      break;
  }
};

export default codeHandler;
