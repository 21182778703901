<template>
  <v-footer padless dark color="pa-5">
    <img class="px-4" :src="require(light ? 'images/logo_foot.png' : 'images/logo.png')" alt="vgo" />
    <span class="pl-4"> 2022 All rights reserved by Vengine Co.,Ltd </span>

    <v-spacer />

    <div class="gutter-mx-6">
      <v-btn text min-width="16px" class="pa-0" @click="openTab('https://endocs.v-go.io/')"> USER GUIDE </v-btn>

      <v-btn text min-width="16px" class="pa-0" @click="openTab('https://tnc.v-go.io/')"> T&C </v-btn>

      <v-btn text min-width="16px" class="pa-0"> E-MAIL CONTACT </v-btn>
    </div>
  </v-footer>
</template>

<script>
export default {
  props: ["light"],
  methods: {
    openTab(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style></style>
