<template>
  <div class="d-flex align-center" :style="draggable ? 'cursor: move' : ''">
    <div class="list-col state">
      <i v-if="getStatusInfo(item.isUse).active" :class="`fa-solid fa-ellipsis-vertical`"></i>
      <span v-if="!getStatusInfo(item.isUse).active" :class="`font-${getStatusInfo(item.isUse).color}`">
        {{ getStatusInfo(item.isUse).text }}
      </span>
    </div>

    <div :class="`list-col name ${getStatusInfo(item.isUse).textClass || ''}`">
      <span class="company">{{ item.companyName }}</span>
      <v-chip label small class="defaultSelected" v-if="draggable && index === 0">Default</v-chip>
    </div>

    <div class="list-col">
      <v-btn
        v-if="isDenied(item.isUse)"
        dark
        color="gray3"
        :elevation="0"
        v-t="'common.button.reason'"
        @click="$emit('checkContents', item.rejectReason)">
      </v-btn>
      <v-btn
        v-else-if="isAdmin(item.userRole) && !isPending(item.isUse)"
        :elevation="0"
        color="#E3F0F3"
        @click="$emit('companyModify', item)">
        <span class="font-secondary">Modify</span>
      </v-btn>
    </div>

    <div class="list-col">
      <v-btn outlined color="secondary" @click="$emit('changeCompanyIsUse', item)" v-if="!isPending(item.isUse)">
        {{ getStatusInfo(item.isUse).buttonText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { company } from "@/utils/statusHandler";

export default {
  props: ["item", "index", "draggable"],
  methods: {
    getStatusInfo: company.requestStatusHandler,
    isAdmin: company.isAdmin,
    isJoin: company.isJoin,
    isDenied: company.isDenied,
    isPending: company.isPending,
  },
};
</script>

<style></style>
