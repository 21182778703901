import Vue from "vue";
import i18n from "@/plugins/i18n";

const errorHandler = function (err) {
  if (!err) return;
  let errorType;

  if (err.response) {
    //statusCode : outside the range of 2xx
    Vue.$toast.error(`${err.response.data.error || err}`);
    errorType = err.response.status;
  } else if (err.request) {
    //no response
    if (err.code === "ECONNABORTED") Vue.$toast.error(i18n.t("axios.timeout"));
    else Vue.$toast.error(i18n.t("axios.noResponse"));
    errorType = "no response";
  } else {
    //axios config error
    Vue.$toast.error(i18n.t("axios.configError"));
    errorType = "config";
  }

  console.error(`axios ${errorType} error`);
  // console.error(err);
};

export default errorHandler;
