export const _isUse = {
  Denied: "D", // 거절 됨 (사유 있음, 회사목록에 표시됨, 재가입신청 가능)
  Waiting: "W", // 가입 신청 중
  Joined: "Y", // 가입 됨
  Cancel: "C", // 가입 신청 중 취소
  Leave: "L", // 본인이 탈퇴
  Exit: "E", // 퇴사자 (회사관리자가 Deactive 처리, 사유 없음, 회사목록에 표시안됨, 재가입신청 불가)
  NotUsed: "N", // Denied 일 경우 Delete || 회사 관리자가 Delete (회사목록에 표시안됨, 재가입신청 가능)
  Pending: "P", // 회사 생성 신청 중
};

export const _role = {
  Manager: "M",
  General: "G",
};

export const company = {
  requestStatusHandler(status) {
    switch (status) {
      case _isUse.Denied:
        return {
          color: "error",
          text: "Denied",
          textClass: "font-gray3",
          buttonText: "DELETE",
        };
      case _isUse.Waiting:
        return {
          color: "blue1",
          text: "In Line",
          textClass: "font-gray3",
          tooltip: true,
          buttonText: "CANCEL",
        };
      case _isUse.Joined:
        return { active: true, buttonText: "LEAVE" };
      case _isUse.Pending:
        return { color: "blue2", text: "Pending", textClass: "font-gray3" };
      default:
        return false;
    }
  },
  isAdmin(role) {
    return role === _role.Manager;
  },
  isJoin(isUse) {
    return isUse === _isUse.Joined;
  },
  isDenied(isUse) {
    return isUse === _isUse.Denied;
  },
  isPending(isUse) {
    return isUse === _isUse.Pending;
  },
  isWaiting(isUse) {
    return isUse === _isUse.Waiting;
  },
};

export const bomStatus = function (status) {
  const Progress = "PROGRESS";
  const Complete = "COMPLETE";

  switch (status) {
    case Progress:
      return "In PROGRESS";
    case Complete:
      return "COMPLETED";
    default:
      return status;
  }
};
