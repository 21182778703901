<template>
  <v-dialog v-model="dialogShow" width="400px" content-class="confirm customEditModal">
    <v-card class="pa-5">
      <div class="content text-center">
        <slot></slot>
      </div>

      <div class="actions d-flex justify-center">
        <v-btn color="secondary" dark :elevation="0" @click="confirmClicked">
          {{ confirmButton && hasValue(confirmButton.text) ? confirmButton.text : "OK" }}
        </v-btn>
        <v-btn color="secondary" dark :elevation="0" @click="cancelClicked">
          {{ cancelButton && hasValue(cancelButton.text) ? cancelButton.text : "CANCEL" }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue } from "@/utils/util";
export default {
  props: ["show", "confirmButton", "cancelButton", "hideEvent"],
  data() {
    return {};
  },
  methods: {
    confirmClicked() {
      this.$emit("confirm", this.confirmButton?.params);
      this.dialogShow = false;
    },
    cancelClicked() {
      this.$emit("cancel", this.cancelButton?.params);
      this.dialogShow = false;
    },
    hasValue,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        if (this.hideEvent === true && value === false) this.$emit("cancel", this.cancelButton.params);
        this.$emit("update:show", value);
      },
    },
  },
};
</script>

<style></style>
