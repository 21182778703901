<template>
  <div :class="[{ fold: miniDrawer }, 'navi-accordion']">
    <div class="nav-wrap">
      <div class="ck-h">
        <ul class="list-wrap">
          <nav-drawer-item
            v-for="(item, index) in items"
            :key="index"
            :title="item.title"
            :icon="item.icon"
            :name="item.name"
            :isOpen.sync="item.isOpen"
            :childs="item.childs"
            :miniDrawer="miniDrawer" />
        </ul>
      </div>
      <button type="button" class="menu-btn" @click="onFoldMenu"></button>
    </div>
  </div>
</template>

<script>
import NavDrawerItem from "@/components/menus/NavDrawerItem.vue";

export default {
  data() {
    return {
      miniDrawer: true,
      items: [
        {
          title: "Material Management",
          icon: "fas fa-book",
          name: "material",
          isOpen: false,
          childs: [
            {
              title: "Material List",
              name: "material",
            },
            // {
            //   title: "Inventory",
            //   name: "",
            // },
          ],
        },
        {
          icon: "fas fa-tshirt",
          title: "BOM",
          name: "BOM",
          isOpen: false,
        },
        {
          icon: "fas fa-clipboard-list",
          title: "Purchase Order",
          name: "PO",
          isOpen: false,
          childs: [
            {
              title: "Issued RM PO",
              name: "issuedRMPO",
            },
            {
              title: "Received RM PO",
              name: "receivedRMPO",
            },
            {
              title: "Manual Garment PO",
              name: "garmentPO",
            },
            {
              title: "API PO (GT Nexus)",
              name: "gtNexus",
            },
          ],
        },
        // {
        //   icon: "fas fa-money-bill-wave",
        //   title: "Payment Information",
        //   isOpen: false,
        //   childs: [
        //     {
        //       title: "Supplier Shipping Info",
        //       name: "",
        //     },
        //     {
        //       title: "Buyer Shipping Info",
        //       name: "",
        //     },
        //     {
        //       title: "Pre/Post Cost report",
        //       name: "",
        //     },
        //   ],
        // },
        {
          icon: "fa-solid fa-square-poll-vertical",
          title: "Financial Plan",
          name: "financialPlan",
          isOpen: false,
        },
        {
          icon: "fas fa-cog",
          title: "System",
          name: "system",
          isOpen: false,
          childs: [
            {
              title: "Data Management",
              name: "dataManagement",
              isManager: true,
            },
            {
              title: "User Management",
              name: "userManagement",
            },
            {
              title: "Company Infomation",
              name: "companyInformation",
              isManager: true,
            },
          ],
        },
      ],
    };
  },
  components: { NavDrawerItem },
  methods: {
    onFoldMenu() {
      if (!this.miniDrawer) {
        this.items.forEach(item => (item.isOpen = false));
      }

      this.miniDrawer = !this.miniDrawer;
    },
  },
};
</script>
