<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        :color="color"
        :dark="dark"
        :size="size"
        :class="iconClass"
        v-bind="attrs"
        v-on="on"
        @click="callback($event)">
        {{ icon }}
      </v-icon>
    </template>
    <span v-html="tooltip"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["icon", "tooltip", "color", "iconClass", "dark", "size"],
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style></style>
