<template>
  <v-dialog v-model="show" width="600px" content-class="company-list-edit-modal">
    <v-card class="pa-5">
      <h1 class="title">Edit Company List</h1>

      <div class="mt-10 list-wrap">
        <draggable
          tag="div"
          animation="200"
          ghostClass="ghost"
          v-model="joinList"
          @start="handleStart"
          @end="handleEnd">
          <div
            v-for="(item, index) in joinList"
            :key="index"
            :data-id="item.id"
            :data-cmpyuuid="item.cmpyUUID"
            class="list-item">
            <my-company-list-edit-row
              :index="index"
              :item="item"
              :draggable="true"
              @changeCompanyIsUse="changeCompanyIsUse"
              @companyModify="companyModify" />
            <!--<v-divider class="my-2" />-->
          </div>
        </draggable>
        <div v-for="(item, index) in notJoinList" :key="index" class="list-item wait">
          <my-company-list-edit-row
            :index="index"
            :item="item"
            @checkContents="checkContents"
            @changeCompanyIsUse="changeCompanyIsUse" />
          <!--<v-divider class="my-2" />-->
        </div>
      </div>
    </v-card>

    <confirm :show.sync="leaveConfirmShow" @confirm="leaveCompany">
      Are you sure you want to leave away? <br />
      You have to be permitted when you join this workspace again.
    </confirm>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";
import MyCompanyListEditRow from "./MyCompanyListEditRow.vue";
import { company, _isUse } from "@/utils/statusHandler";
import { hasValue } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
import Confirm from "@/components/common/cards/Confirm.vue";

export default {
  props: ["listEditShow"],
  data() {
    return {
      moveTargetItem: null,
      leaveConfirmShow: false,
      leaveConfirmTarget: null,
    };
  },
  components: {
    draggable,
    MyCompanyListEditRow,
    Confirm,
  },
  methods: {
    handleStart({ item }) {
      this.moveTargetItem = {
        id: item.dataset.id,
        cmpyUUID: item.dataset.cmpyuuid,
      };
    },
    handleEnd({ newIndex, oldIndex }) {
      if (newIndex === oldIndex) return;
      this.$http.put("/user/company/sort", {
        oldIndex: oldIndex + 1,
        newIndex: newIndex + 1,
        id: parseInt(this.moveTargetItem.id),
        cmpyUUID: this.moveTargetItem.cmpyUUID,
      });
    },
    checkContents(rejectReason) {
      this.$toast.info(rejectReason);
    },
    changeCompanyIsUse({ id, cmpyUUID, isUse }) {
      if (![id, cmpyUUID, isUse].every(hasValue)) return console.error("changeCompanyIsUse - parameter error");

      let status = undefined;
      switch (isUse) {
        case _isUse.Denied:
          status = _isUse.NotUsed;
          break;
        case _isUse.Waiting:
          status = _isUse.Cancel;
          break;
        case _isUse.Joined:
          status = _isUse.Leave;
          this.leaveConfirmTarget = { id, cmpyUUID, isUse: status };
          this.leaveConfirmShow = true;
          return;
        default:
          return console.error(`changeCompanyIsUse - isUse error - ${isUse}`);
      }

      this.putStatus({ id, cmpyUUID, isUse: status });
    },
    putStatus(params) {
      this.$http.put("/user/company/status", params).then(res => {
        if (res.data.status === 200) {
          this.$store.dispatch("user/getCompanyList");
        } else codeHandler(res.data);
      });
    },
    leaveCompany() {
      this.putStatus(this.leaveConfirmTarget);
    },
    companyModify(item) {
      this.$router.push({ name: "companyInformation", params: { cmpyUUID: item.cmpyUUID } });
      this.show = false;
    },
    getStatusInfo: company.requestStatusHandler,
    isAdmin: company.isAdmin,
    isJoin: company.isJoin,
  },
  computed: {
    show: {
      get() {
        return this.listEditShow;
      },
      set(value) {
        this.$emit("update:listEditShow", value);
      },
    },
    joinList: {
      get() {
        return this.$store.state.user.companyList?.filter(({ isUse }) => this.isJoin(isUse));
      },
      set(value) {
        const companyList = value.concat(this.notJoinList);
        this.$store.commit("user/setCompanyList", { companyList });
      },
    },
    notJoinList() {
      return this.$store.state.user.companyList?.filter(({ isUse }) => !this.isJoin(isUse));
    },
  },
};
</script>

<style></style>
