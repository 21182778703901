import { format } from "mathjs";

export const hasValue = function (val) {
  let result;

  if (Array.isArray(val)) result = val.length > 0;
  else result = val !== undefined && val !== null && val !== false && val !== "";

  return result;
};

export const numberWithComma = function (x) {
  return x?.toString()?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") || 0;
};

export const isNumber = function (x, notRequired) {
  if (notRequired && (x === undefined || x === null || x === "" || x === 0)) return true;
  return hasValue(x) && /^-?[0-9]\d*(\.\d+)?$/.test(x);
};

export const fixedNumber = function (x, n, notNaN) {
  if (!isNumber(x) || !isNumber(n)) return notNaN ? 0 : NaN;
  const val = parseFloat(parseFloat(x).toFixed(parseFloat(n)));
  return notNaN && !isNumber(val) ? 0 : val;
};

export const calc = function (x) {
  if (x === Infinity || x === -Infinity || isNaN(x)) return 0;
  if (typeof x !== "number" && typeof parseFloat(x) !== "number") return 0;

  const formatNum = format(parseFloat(x), { precision: 15, upperExp: 20, lowerExp: -10 });

  if (isNumber(formatNum)) return parseFloat(formatNum);
  else return formatNum;
};

export const _deepCopy = function (obj) {
  if (obj === null || typeof obj !== "object" || "isActiveClone" in obj) return obj;

  let temp;
  if (obj instanceof Date) temp = new obj.constructor();
  else temp = obj.constructor();

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj["isActiveClone"] = null;
      temp[key] = _deepCopy(obj[key]);
      delete obj["isActiveClone"];
    }
  }

  return temp;
};

export const isDuplicate = function (arr) {
  const isDup = arr.some(function (x) {
    return arr.indexOf(x) !== arr.lastIndexOf(x);
  });

  return isDup;
};

export const toCamelCase = function (str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};
