import moment from "moment";
import i18n from "./i18n";
import "moment/locale/ko";

class Instance {
  constructor() {
    moment.locale(i18n.locale);
    this.moment = moment;
    this.moment.updateLocale(moment.locale(), { invalidDate: "" });
  }

  changeLocale() {
    this.moment.locale(i18n.locale);
    this.moment.updateLocale(moment.locale(), { invalidDate: "" });
  }
}

export default new Instance();
