import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
import loginRouter from "./modules/login";
import companyRouter from "./modules/company";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    redirect: () => {
      //비로그인 유저는 beforeEach에서 login페이지로 이동됨
      const joinCompanyList = store.getters["user/getCompanyList"];
      if (joinCompanyList && joinCompanyList.length > 0) return { path: `/company/${joinCompanyList[0].value}` };
      else return { name: "community" };
    },
  },
  {
    path: "/login",
    component: () => import("@/views/LoginPage"),
    children: loginRouter,
  },
  {
    path: "/community",
    name: "community",
    component: () => import("@/views/CommunityPage"),
  },
  {
    path: "/company/:cmpyUUID",
    component: () => import("@/views/CompanyPage"),
    children: companyRouter,
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/AdminPage"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("setCurrentRoute", { route: to });
  if (to.path.substring(0, "/login".length) !== "/login" && !store.state.user.token) {
    //비로그인
    next({ name: "login" });
  } else if (to.path.substring(0, "/company".length) === "/company") {
    //회사 페이지 > 소속된 회사 확인
    if (store.getters["user/includesCompany"](to.params.cmpyUUID)) {
      store.dispatch("company/getCompanyCode", to.params.cmpyUUID);
      next();
    } else next({ name: "community" });
  } else if (to.name === "admin" && store.state.user.userRole <= 0) next();
  //관리자 페이지
  else next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

export default router;
