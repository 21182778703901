<template>
  <v-app>
    <app-bar v-if="!isLogin" />
    <v-main>
      <nav-drawer v-if="!isLogin && isCompany" />
      <v-container fluid :class="[!isLogin ? 'pa-4' : 'pa-0 login', 'ma-0 fh']">
        <router-view />
      </v-container>
    </v-main>
    <app-footer v-if="isLogin || isCommunity || isCompanyHome" :class="{ 'login-foot': isLogin }" :light="isLogin" />
  </v-app>
</template>

<script>
import AppBar from "@/components/menus/AppBar.vue";
import NavDrawer from "@/components/menus/NavDrawer.vue";
import AppFooter from "@/components/menus/AppFooter.vue";

export default {
  name: "App",
  components: { AppBar, NavDrawer, AppFooter },
  data() {
    return {};
  },
  methods: {
    getChaneelTalk() {
      (function () {
        var w = window;
        if (w.ChannelIO) {
          return (window.console.error || window.console.log || function () {})("ChannelIO script included twice.");
        }
        var ch = function () {
          ch.c(arguments);
        };
        ch.q = [];
        ch.c = function (args) {
          ch.q.push(args);
        };
        w.ChannelIO = ch;
        function l() {
          if (w.ChannelIOInitialized) {
            return;
          }
          w.ChannelIOInitialized = true;
          var s = document.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
          s.charset = "UTF-8";
          var x = document.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        }
        if (document.readyState === "complete") {
          l();
        } else if (window.attachEvent) {
          window.attachEvent("onload", l);
        } else {
          window.addEventListener("DOMContentLoaded", l, false);
          window.addEventListener("load", l, false);
        }
      })();

      window.ChannelIO("boot", { pluginKey: "06691fa9-80bb-4c09-9093-a78f461d7c30" });
    },
  },
  computed: {
    isLogin() {
      return this.$store.getters.currentRouteIsLogin;
    },
    isCompany() {
      return this.$store.getters.currentRouteIsCompany;
    },
    isCompanyHome() {
      return this.$store.state.currentRoute.name === "company";
    },
    isCommunity() {
      return this.$store.getters.currentRouteIsCommunity;
    },
  },
  mounted() {
    this.$store.dispatch("code/getCommonCode");
    this.getChaneelTalk();
  },
};
</script>
