import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@/scss/_index.scss";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#277C70",
        secondary: "#3A989E",
        blue1: "#507BAA",
        blue2: "#92A9C3",
        blue3: "#CFE6FF",
        blue4: "#EAF3FD",
        success: "#27AE60",
        warning: "#E2B93B",
        error: "#EB5757",
        red1: "#C39592",
        red2: "#FEF1F1",
        black: "#222222",
        white: "#FFFFFF",
        gray1: "#555555",
        gray2: "#888888",
        gray3: "#999999",
        gray4: "#BCBCBC",
        gray5: "#DDDDDD",
        gray6: "#EEEEEE",
      },
    },
  },
});
