import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import user from "./modules/userStore";
import company from "./modules/companyStore";
import code from "./modules/codeStore";

export default new Vuex.Store({
  modules: {
    user,
    company,
    code,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["user", "code"],
    }),
  ],
  state: {
    currentRoute: undefined,
  },
  mutations: {
    setCurrentRoute(state, { route }) {
      state.currentRoute = route;
    },
  },
  getters: {
    currentRouteIsLogin(state) {
      const rootPath = "/login";
      return state.currentRoute?.path.substring(0, rootPath.length) === rootPath;
    },
    currentRouteIsCompany(state) {
      const rootPath = "/company";
      return state.currentRoute?.path.substring(0, rootPath.length) === rootPath;
    },
    currentRouteIsCommunity(state) {
      return state.currentRoute?.name === "community";
    },
    currentRouteIsMaterial(state) {
      return state.currentRoute?.matched[1]?.name === "material";
    },
    currentRouteIsBOM(state) {
      return (
        state.currentRoute?.matched[1]?.name === "BOM" ||
        state.currentRoute?.matched[1]?.name === "CBD" ||
        state.currentRoute?.matched[1]?.name === "MCL"
      );
    },
    currentRouteIsPurchaseOrder(state) {
      return (
        state.currentRoute?.matched[1]?.name === "issuedRMPO" ||
        state.currentRoute?.matched[1]?.name === "issuedRMPODetail" ||
        state.currentRoute?.matched[1]?.name === "receivedRMPO" ||
        state.currentRoute?.matched[1]?.name === "receivedRMPODetail" ||
        state.currentRoute?.matched[1]?.name === "garmentPO" ||
        state.currentRoute?.matched[1]?.name === "garmentPODetail" ||
        state.currentRoute?.matched[1]?.name === "gtNexus"
      );
    },
    currentRouteIsSystem(state) {
      return (
        state.currentRoute?.matched[1]?.name === "dataManagement" ||
        state.currentRoute?.matched[1]?.name === "userManagement" ||
        state.currentRoute?.matched[1]?.name === "companyInformation"
      );
    },
    currentRouteIsFinancialPlan(state) {
      return state.currentRoute?.matched[1]?.name === "financialPlan";
    },
    getCmpyUUID(state) {
      return state.currentRoute.params.cmpyUUID;
    },
    getCmpyName(state) {
      const cmpyUUID = state.currentRoute.params.cmpyUUID;
      return state.user.companyList.find(c => c.cmpyUUID === cmpyUUID)?.companyName;
    },
  },
});
